import { parseValuation } from 'server/type-file';
import { isNil } from 'lodash-es';
import Nzh from 'nzh';
import type { RouterOutputs } from '../../../utils/trpc';
import { constantLease } from '../edit/categorySelectOption';
import { bigConstruct } from './bigConstruct';
import { clacThisMonthLastMonth } from './clacThisMonthLastMonth';

export function calcFee(
	apartment: RouterOutputs['apartment']['list']['list'][number],
	config: unknown,
) {
	const valuation = parseValuation(config);

	const numericApartment = {
		...apartment,
		rent: bigConstruct(apartment.rent),
		deposit: bigConstruct(apartment.deposit),
		electricMeterReadingThisMonth: bigConstruct(
			apartment.electricMeterReadingThisMonth,
		),
		electricMeterReadingLastMonth: bigConstruct(
			apartment.electricMeterReadingLastMonth,
		),
		coldWaterMeterReadingThisMonth: bigConstruct(
			apartment.coldWaterMeterReadingThisMonth,
		),
		coldWaterMeterReadingLastMonth: bigConstruct(
			apartment.coldWaterMeterReadingLastMonth,
		),
		hotWaterMeterReadingThisMonth: bigConstruct(
			apartment.hotWaterMeterReadingThisMonth,
		),
		hotWaterMeterReadingLastMonth: bigConstruct(
			apartment.hotWaterMeterReadingLastMonth,
		),
		networkFee: bigConstruct(apartment.networkFee),
		tvFee: bigConstruct(apartment.tvFee),
		cleanFee: bigConstruct(apartment.cleanFee),
	};

	let coldWaterUnitPrice = valuation.coldWaterFee.value;
	let electricUnitPrice = valuation.electricityFee.value;
	let hotWaterUnitPrice = valuation.hotWaterFee.value;

	// 旧版逻辑
	if (
		// @ts-expect-error 旧版逻辑
		valuation?.storeColdWaterFee &&
		// @ts-expect-error 旧版逻辑
		valuation?.storeElectricityFee &&
		// @ts-expect-error 旧版逻辑
		valuation?.storeHotWaterFee
	) {
		if (apartment.floorLevel === '1') {
			// @ts-expect-error 旧版逻辑
			coldWaterUnitPrice = valuation.storeColdWaterFee.value;
			// @ts-expect-error 旧版逻辑
			electricUnitPrice = valuation.storeElectricityFee.value;
			// @ts-expect-error 旧版逻辑
			hotWaterUnitPrice = valuation.storeHotWaterFee.value;
		} else {
			coldWaterUnitPrice = valuation.coldWaterFee.value;
			electricUnitPrice = valuation.electricityFee.value;
			hotWaterUnitPrice = valuation.hotWaterFee.value;
		}
	}

	const totalElectricUsed = clacThisMonthLastMonth(
		apartment?.electricMeterReadingThisMonth,
		apartment?.electricMeterReadingLastMonth,
	);

	const totalColdWaterUsed = clacThisMonthLastMonth(
		apartment?.coldWaterMeterReadingThisMonth,
		apartment?.coldWaterMeterReadingLastMonth,
	);

	const totalHotWaterUsed = clacThisMonthLastMonth(
		apartment?.hotWaterMeterReadingThisMonth,
		apartment?.hotWaterMeterReadingLastMonth,
	);

	const totalElectricCharge = totalElectricUsed
		.times(electricUnitPrice)
		.round(2);

	const totalColdWaterCharge = totalColdWaterUsed
		.times(coldWaterUnitPrice)
		.round(2);

	const totalHotWaterCharge = totalHotWaterUsed
		.times(hotWaterUnitPrice)
		.round(2);

	let deposit = bigConstruct(apartment?.deposit);

	if (apartment.category === constantLease && !isNil(apartment.deposit)) {
		deposit = bigConstruct(deposit).times(-1);
	} else {
		deposit = bigConstruct(deposit);
	}

	const amount = deposit
		.plus(bigConstruct(apartment?.rent))
		.plus(bigConstruct(apartment?.cleanFee))
		.plus(bigConstruct(apartment?.tvFee))
		.plus(bigConstruct(apartment?.networkFee))
		.plus(bigConstruct(totalElectricCharge))
		.plus(bigConstruct(totalColdWaterCharge))
		.plus(bigConstruct(totalHotWaterCharge))
		.round(2);

	const capitalFormOfAmount = Nzh.cn.encodeB(amount.toNumber(), {});

	const fee = {
		electricUnitPrice: electricUnitPrice,
		hotWaterUnitPrice: hotWaterUnitPrice,
		coldWaterUnitPrice: coldWaterUnitPrice,

		totalElectricUsed: totalElectricUsed,
		totalColdWaterUsed: totalColdWaterUsed,
		totalHotWaterUsed: totalHotWaterUsed,
		totalElectricCharge: totalElectricCharge,
		totalColdWaterCharge: totalColdWaterCharge,
		totalHotWaterCharge: totalHotWaterCharge,
		amount: amount,
		capitalFormOfAmount: capitalFormOfAmount,
		deposit: deposit,
	};

	return {
		...numericApartment,
		...fee,
		valuation: valuation,
	};
}
